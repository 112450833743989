import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useForm, useFormContext } from "react-hook-form";
import { exportMatches_date } from "../Evenements/zones/ExportExcel";
import { CustomStepper } from "../_shared/interaction/Stepper";

interface DepotComponentProps {
  onFilesDropped: (validFiles: File[], invalidFiles: File[]) => void;
  currentStep: number;
  handleContinueToStepTwo: () => void;
  methods: ReturnType<typeof useForm>; // Ajoutez cette ligne pour typer methods
}

export const DepotComponent = ({
  onFilesDropped,
  currentStep,
  handleContinueToStepTwo,
  methods,
}: DepotComponentProps) => {
  const { setValue, watch } = useFormContext();
  const files = watch("files");
  const [rejectedFilesCount, setRejectedFilesCount] = useState<number>(0);

  const isValidFileName = (fileName: string) => {
    const regex = /^\d{5}_\d{2}_\d{4}.*\.pdf$/;
    return regex.test(fileName);
  };

  const onDrop = useCallback(
    (acceptedFiles: any[]) => {
      const validFiles = acceptedFiles.filter((file: any) =>
        isValidFileName(file.name)
      );
      const invalidFiles = acceptedFiles.filter(
        (file: any) => !isValidFileName(file.name)
      );

      setValue("files", validFiles);
      setRejectedFilesCount(rejectedFilesCount + invalidFiles.length);
      onFilesDropped(validFiles, invalidFiles);
    },
    [setValue, onFilesDropped, rejectedFilesCount]
  );

  const onDropRejected = useCallback(() => {
    setRejectedFilesCount((count: number) => count + 1);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
  });

  return (
    <Container py={{ base: "4", md: "8" }} px={{ base: "0", md: 2 }}>
      <Box>
        <Stack>
          <Box>
            <Box py="12">
              <Flex justify="space-between" align="center" py="1">
                <Heading
                  size={useBreakpointValue({ base: "xs", md: "sm" })}
                  color="primary"
                >
                  Dépôts automatiques de fichier pour les vacataires
                </Heading>
              </Flex>
              <Text color="muted">
                Déposez les fichiers bilan financier et autres fichiers
                similaires contenant un numéro de matricule
              </Text>
            </Box>
            <CustomStepper currentStep={currentStep} />
            <RadioGroup defaultValue="Bulletin de paie">
              <Stack direction="row" alignItems="center" mr={4}>
                <Radio
                  {...methods.register("documentType")}
                  value="Bulletin de paie"
                >
                  Bulletin de paie
                </Radio>
              </Stack>
            </RadioGroup>
            <VStack spacing={4}>
              <Box
                {...getRootProps()}
                w="100%"
                h="100px"
                border="2px dashed"
                borderColor={
                  files && files.length > 0 ? "green.500" : "red.500"
                }
                display="flex"
                justifyContent="center"
                alignItems="center"
                _hover={{ bg: "gray.100" }}
                cursor="pointer"
                role="button"
              >
                <input {...getInputProps()} name="files" />
                <Text padding={4}>
                  {isDragActive
                    ? "Déposez les fichiers ici..."
                    : `Vous avez déposez ${
                        files ? files.length : 0
                      } fichier(s) valide(s). ${rejectedFilesCount} fichier(s) rejeté(s).`}
                </Text>
              </Box>
            </VStack>
          </Box>
        </Stack>
      </Box>
      <Button
        onClick={handleContinueToStepTwo}
        variant="primaryButton"
        mt={4}
        isDisabled={!files || files.length === 0}
      >
        Continuer
      </Button>
      <br />
      <br />

      <button
        onClick={exportMatches_date}
        style={{
          padding: "5px",
          background: "grey",
          color: "white",
          border: "none",
          cursor: "pointer",
        }}
      >
        Exporter les MATCHS
      </button>
    </Container>
  );
};
