import ExcelJS from "exceljs";
import { API_EVENEMENT, API_URL } from "../../../api";
import { getVacataireMatricule } from "../../../api/ApiVacataire";
import { fetchZonesForEventAndService } from "../../../api/zones/VacatairesToZone";
import { PersonnelRolesLabels } from "../../../utils/types/EnumPost";
import { STATUS_ACCEPT } from "./EvenementZones";

export const generateServiceXLSX = async (service: any, event: any) => {
  const imageEtms = "/images/logo/ETMS.png";
  const imageHospitality = "/images/logo/TH.png";
  async function fetchImageAsBase64(url: string): Promise<string> {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
  try {
    const zones = await fetchZonesForEventAndService(event._id, service.name);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Feuille de Margement");

    const serviceHeaderData = [
      ["Événement", event?.name_event],
      [
        "Date",
        event?.date_event
          ? new Date(event.date_event * 1000).toLocaleDateString("fr-FR", {
              weekday: "long",
              day: "2-digit",
              month: "long",
              year: "numeric",
            })
          : "Aucune date d'évènement spécifiée",
      ],
      [
        "Lieu",
        event?.place
          ? event.place.name
          : "Aucune adresse d'évènement spécifiée",
      ],
      ["Service", service.name],
    ];
    const selectedImageUrl =
      service.name === "Sécurité" ? imageEtms : imageHospitality;

    const selectedImageData = await fetchImageAsBase64(selectedImageUrl);

    const imageId = workbook.addImage({
      base64: selectedImageData,
      extension: "png",
    });
    let originalWidth = 148;
    let originalHeight = 95;

    let newHeight = originalHeight * 2;
    let newWidth = originalWidth * (newHeight / originalHeight);

    worksheet.addImage(imageId, {
      tl: { col: 9, row: 0 },
      ext: { width: newWidth, height: newHeight },
    });

    serviceHeaderData.forEach((row, rowIndex) => {
      row.forEach((cell, cellIndex) => {
        const excelCell = worksheet.getCell(rowIndex + 1, cellIndex + 1);
        excelCell.value = cell;
        excelCell.alignment = {
          wrapText: true,
          horizontal: "center",
          vertical: "middle",
        };
        if (cellIndex === 0) {
          excelCell.font = { color: { argb: "FFFF0000" } };
        } else {
          excelCell.font = { bold: true };
        }
      });
    });

    let currentRow = serviceHeaderData.length + 2;

    for (const zone of zones) {
      const vacatairesFiltres = zone.vacataires.filter(
        (vacataire: any) => vacataire.status === STATUS_ACCEPT
      );

      const zoneCell = worksheet.getCell(currentRow, 1);
      zoneCell.value = `Zone: ${zone.name}`;
      zoneCell.alignment = {
        wrapText: true,
        horizontal: "center",
        vertical: "middle",
      };
      zoneCell.font = { bold: true };
      worksheet.mergeCells(currentRow, 1, currentRow, 11);
      currentRow++;

      const headers = [
        "MATRICULE",
        "NOM",
        "PRÉNOM",
        "POSTE",
        "HEURE DE CONVOCATION",
        "HEURE RÉELLE D'ARRIVEE",
        "SIGNATURE À L'ARRIVÉE (SI RETARD)",
        "TEMPS DE PAUSE (NON RÉMUNÉRÉ)",
        "HEURE DE FIN",
        "SIGNATURE AU DEPART",
        "PROCHAIN ÉVENÈMENT    ................",
      ];
      headers.forEach((header, index) => {
        const headerCell = worksheet.getCell(currentRow, index + 1);
        headerCell.value = header;
        headerCell.font = { bold: true };
        headerCell.alignment = {
          wrapText: true,
          horizontal: "center",
          vertical: "middle",
        };
      });
      currentRow++;

      // Ajouter les données des vacataires
      for (const vacataire of vacatairesFiltres) {
        const convertTimestampToFrenchHour = (timestamp: number) => {
          const date = new Date(timestamp * 1000); // Multiplier par 1000 car on suppose que le timestamp est en secondes
          let timeString = date.toLocaleTimeString("fr-FR", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          });
          timeString = timeString.replace(":", "h");
          return timeString;
        };
        const matricule = await getVacataireMatricule(
          vacataire.vacataireId,
          service.name
        );

        const startHourFrench = vacataire.startHour
          ? convertTimestampToFrenchHour(vacataire.startHour)
          : "Non renseigné";

        const roleLabel =
          PersonnelRolesLabels[
            vacataire.fonction as keyof typeof PersonnelRolesLabels
          ] || "Non renseigné";
        const row = [
          matricule || "Non renseigné",
          vacataire.name,
          vacataire.firstname,
          roleLabel || "Non renseigné",
          startHourFrench,
          "",
          "",
          "",
          "",
          "",
          "",
        ];
        row.forEach((cell, index) => {
          worksheet.getCell(currentRow, index + 1).value = cell;
        });
        currentRow++;
      }
      for (let i = 0; i < 5; i++) {
        let newRow = worksheet.addRow([
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ]);
        newRow.eachCell((cell, colNumber) => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
        currentRow++;
      }
      currentRow++;
    }

    worksheet.eachRow({ includeEmpty: false }, function (row, rowNumber) {
      row.height = 40;

      row.eachCell({ includeEmpty: false }, function (cell, colNumber) {
        cell.font = {
          size: 13,
          bold: cell.font ? cell.font.bold : false,
          color: cell.font ? cell.font.color : { argb: "FF000000" },
        };
        cell.alignment = {
          wrapText: true,
          horizontal: "center",
          vertical: "middle",
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    });

    worksheet.pageSetup = {
      paperSize: 8, //8 A3 9A4
      orientation: "landscape",
      fitToPage: true,
      fitToWidth: 1,
      fitToHeight: 0,
    };

    worksheet.pageSetup.margins = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };

    worksheet.columns = [
      { width: 12 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 15 },
      { width: 15 },
      { width: 20 },
      { width: 15 },
      { width: 15 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const fileName = `${event.name_event}_${service.name}_feuille_de_margement.xlsx`;

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  } catch (error) {
    console.error("Error generating Excel for service:", error);
  }
};

export const generateEventXLSX = async (event: any) => {
  console.log("===>");
  console.log(event);
  const imageEtms = "/images/logo/ETMS.png";
  const imageHospitality = "/images/logo/TH.png";
  try {
    const zones = await fetchZonesForEventAndService(
      event._id,
      event.coop.name
    );

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Feuille de Margement");

    const eventHeaderData = [
      ["Événement", event?.name_event],
      [
        "Date",
        event?.date_event
          ? new Date(event.date_event * 1000).toLocaleDateString("fr-FR", {
              weekday: "long",
              day: "2-digit",
              month: "long",
              year: "numeric",
            })
          : "Aucune date d'évènement spécifiée",
      ],
      [
        "Lieu",
        event?.place
          ? event.place.name
          : "Aucune adresse d'évènement spécifiée",
      ],
      [
        "Référence",
        event?.analytic_code
          ? event.analytic_code
          : "Aucun code analytique spécifiée",
      ],
    ];

    eventHeaderData.forEach((row, rowIndex) => {
      row.forEach((cell, cellIndex) => {
        const excelCell = worksheet.getCell(rowIndex + 1, cellIndex + 1);
        excelCell.value = cell;
        excelCell.alignment = {
          wrapText: true,
          horizontal: "center",
          vertical: "middle",
        };
        if (cellIndex === 0) {
          excelCell.font = { color: { argb: "FFFF0000" } }; // Rouge
        } else {
          excelCell.font = { bold: true };
        }
      });
    });

    let currentRow = eventHeaderData.length + 2;
    for (const service of event.coop) {
      worksheet.getCell(currentRow, 1).value = "Service";
      worksheet.getCell(currentRow, 1).font = { color: { argb: "FFFF0000" } }; // Rouge
      worksheet.getCell(currentRow, 2).value = service.name;
      worksheet.getCell(currentRow, 2).font = { bold: true }; // Gras
      currentRow++;
      const zones = await fetchZonesForEventAndService(event._id, service.name);

      for (const zone of zones) {
        worksheet.getCell(currentRow, 1).value = "Zone";
        worksheet.getCell(currentRow, 1).font = { color: { argb: "FFFF0000" } }; // Rouge
        worksheet.getCell(currentRow, 2).value = zone.name;
        worksheet.getCell(currentRow, 2).font = { bold: true }; // Gras
        currentRow++;
        const vacatairesFiltres = zone.vacataires.filter(
          (vacataire: any) => vacataire.status === STATUS_ACCEPT
        );

        const headers = [
          "MATRICULE",
          "NOM",
          "PRÉNOM",
          "POSTE",
          "HEURE DE CONVOCATION",
          "HEURE RÉELLE D'ARRIVEE",
          "SIGNATURE À L'ARRIVÉE (SI RETARD)",
          "TEMPS DE PAUSE (NON RÉMUNÉRÉ)",
          "HEURE DE FIN",
          "SIGNATURE AU DEPART",
          "PROCHAIN ÉVENÈMENT    ................",
        ];
        headers.forEach((header, index) => {
          const headerCell = worksheet.getCell(currentRow, index + 1);
          headerCell.value = header;
          headerCell.font = { bold: true };
          headerCell.alignment = {
            wrapText: true, // Permet au texte de passer à la ligne automatiquement
            horizontal: "center", // Centre horizontalement
            vertical: "middle", // Centre verticalement
          };
        });
        currentRow++;

        for (const vacataire of vacatairesFiltres) {
          const convertTimestampToFrenchHour = (timestamp: number) => {
            const date = new Date(timestamp * 1000); // Multiplier par 1000 car on suppose que le timestamp est en secondes
            let timeString = date.toLocaleTimeString("fr-FR", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            });
            timeString = timeString.replace(":", "h");
            return timeString;
          };
          const matricule = await getVacataireMatricule(
            vacataire.vacataireId,
            service.name
          );
          const startHourFrench = vacataire.startHour
            ? convertTimestampToFrenchHour(vacataire.startHour)
            : "Non renseigné";

          const roleLabel =
            PersonnelRolesLabels[
              vacataire.fonction as keyof typeof PersonnelRolesLabels
            ] || "Non renseigné";
          const row = [
            matricule || "Non renseigné",

            vacataire.name,
            vacataire.firstname,
            roleLabel || "Non renseigné",
            startHourFrench,
            "",
            "",
            "",
            "",
            "",
            "",
          ];
          row.forEach((cell, index) => {
            worksheet.getCell(currentRow, index + 1).value = cell;
          });
          currentRow++;
        }

        currentRow++; // Ligne vide après chaque zone
      }
    }
    for (let i = 0; i < 5; i++) {
      let newRow = worksheet.addRow([
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]);
      newRow.eachCell((cell, colNumber) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
      currentRow++;
    }
    worksheet.eachRow({ includeEmpty: false }, function (row, rowNumber) {
      row.height = 40;

      row.eachCell({ includeEmpty: false }, function (cell, colNumber) {
        cell.font = {
          size: 13,
          bold: cell.font ? cell.font.bold : false,
          color: cell.font ? cell.font.color : { argb: "FF000000" },
        };
        cell.alignment = {
          wrapText: true,
          horizontal: "center",
          vertical: "middle",
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    });

    worksheet.pageSetup = {
      paperSize: 8, //8 A3 9A4
      orientation: "landscape",
      fitToPage: true,
      fitToWidth: 1,
      fitToHeight: 0,
    };
    worksheet.pageSetup.margins = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };

    worksheet.columns = [
      { width: 12 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 15 },
      { width: 15 },
      { width: 20 },
      { width: 15 },
      { width: 15 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];
    // Sauvegardez le fichier
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const fileName = `${event.name_event}_feuille_de_margement.xlsx`;

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  } catch (error) {
    console.error("Error generating Excel for event:", error);
  }
};

export const exportMatches_date = async () => {
  try {
    const response = await fetch(
      `${API_URL}${API_EVENEMENT}/export-matchs?from=2024-09-20`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const events = await response.json(); // Liste des événements

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Export MATCHS");

    worksheet.columns = [
      { header: "Match", key: "match", width: 50 },
      { header: "Service", key: "service", width: 30 },
      { header: "Vacataire", key: "vacataire", width: 30 },
      { header: "Email", key: "email", width: 50 },
    ];

    for (const event of events) {
      // Convertir la date de l'événement (timestamp) en une date lisible
      const eventDate = event.date_event
        ? new Date(event.date_event * 1000).toLocaleDateString("fr-FR", {
            weekday: "long",
            day: "2-digit",
            month: "long",
            year: "numeric",
          })
        : "Date non spécifiée";

      // Ajouter le match avec la date et un background colorisé
      const matchRow = worksheet.addRow({
        match: `${event.name_event} - ${eventDate}`,
      });
      matchRow.eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFD9EAD3" }, // Couleur de fond vert clair pour les matchs
        };
        cell.font = { bold: true }; // Texte en gras
        cell.alignment = { horizontal: "center", vertical: "middle" };
      });

      for (const service of event.coop) {
        // Ajouter le nom du service avec un fond jaune pastel
        const serviceRow = worksheet.addRow({ service: `  ${service.name}` });
        serviceRow.eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF4CC" }, // Jaune pastel
          };
          cell.font = { italic: true }; // Texte en italique
          cell.alignment = { horizontal: "left" }; // Aligné à gauche
        });

        // Récupérer les zones associées à ce service
        const zonesResponse = await fetch(
          `${API_URL}/zones-vacataire/zones-date?eventId=${event._id}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!zonesResponse.ok) {
          throw new Error(`Error fetching zones for event ${event.name_event}`);
        }

        const zones = await zonesResponse.json();

        // Filtrer les zones pour le service actuel
        const zonesForService = zones.filter(
          (zone: any) => zone.service === service.name
        );

        // Parcourir les zones pour récupérer les vacataires
        for (const zone of zonesForService) {
          // Filtrer les vacataires ayant le statut "Présent(e)"
          const vacatairesFiltres = zone.vacataires.filter(
            (vacataire: any) => vacataire.status === "Présent(e)" // Ajuste selon le statut exact
          );

          // Ajouter les vacataires sous leur service
          for (const vacataire of vacatairesFiltres) {
            worksheet.addRow({
              vacataire: `${vacataire.firstname} ${vacataire.name}`,
              email: vacataire.mail,
            });
          }
        }
      }

      worksheet.addRow({}); // Ligne vide pour séparer les événements
    }

    // Générer le fichier Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "export_matchs.xlsx";
    link.click();
  } catch (error) {
    console.error("Error exporting matches:", error);
  }
};
